<template>
  <section class="time">
    <loader v-if="loading" />
    <div class="time_header">Управление режимом работы филиалов</div>
    <div class="time_box">
      <p class="mb-0">Выберите филиал</p>
      <v-select
        v-model="depId"
        hide-details
        dense
        :items="USER.userInfo.departments"
        item-text="name"
        item-value="id"
        class="time_input"
        @change="setBody($event)"
      >
        <template v-slot:append>
          <v-icon color="#51C8F0">mdi-menu-down</v-icon>
        </template>
      </v-select>
    </div>
    <v-simple-table v-if="WORK_TIME.length" height="64vh" class="table">
      <thead>
        <tr class="table_header">
          <th>День</th>
          <th>Начало работы</th>
          <th>Конец работы</th>
          <th>Изменить</th>
        </tr>
      </thead>
      <tbody style="max-height: 92vh; min-height: 64vh">
        <tr v-for="day in WORK_TIME" :key="day.id">
          <td>{{ returnRuDay(day.day) }}</td>
          <td>{{ day.startTime || "Выходной" }}</td>
          <td>{{ day.endTime || " - - : - -" }}</td>
          <td>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="#5d4bd0"
                  v-bind="attrs"
                  :disabled="!superAdmin"
                  v-on="on"
                  @click="redactDay(day)"
                >
                  <v-icon>mdi-pencil-circle</v-icon>
                </v-btn>
              </template>
              <span>Изменить время</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div v-else class="error-box">
      <p>У данного филиала не указано время работы</p>
      <v-btn color="#5d4bd0" dark @click="showCreate = true"
        >Добавить время работы</v-btn
      >
    </div>
    <RedactDay
      :show.sync="show"
      :edited-day="editedDay"
      @update-time="setBody(depId)"
    />
    <CreateWeek
      :show.sync="showCreate"
      :department-id="depId"
      @update-time="setBody(depId)"
    />
  </section>
</template>

<script>
import Loader from "../components/Loader.vue";
import RedactDay from "../components/TimeControl/RedactDay.vue";
import CreateWeek from "../components/TimeControl/CreateWeek.vue";
import returnRuDayFunc from "@/Functions/returnRuDay";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { Loader, RedactDay, CreateWeek },
  data() {
    return {
      loading: false,
      depId: 0,
      show: false,
      showCreate: false,
      editedDay: null,
      superAdmin: false,
    };
  },
  computed: {
    ...mapGetters({
      DEPARTMENT: "Department/STATE",
      USER: "User/STATE",
      CITY: "City/STATE",
      WORK_TIME: "TimeControl/WORK_TIME",
    }),
  },
  async mounted() {
    this.loading = true;
    await this.checkPowers();
    await this.getDepartment(this.$root.city);
    await this.setBody(this.depId);
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getWorkTime: "TimeControl/GET_WORKTIME",
      deleteWorkTime: "TimeControl/DELETE_WORKTIME",
      getDepartment: "Department/GET_DEPARTMENT",
    }),
    checkPowers() {
      let hasPower = this.$power.check(this.$route.meta.power);
      if (hasPower) {
        this.superAdmin = true;
        this.depId =
          this.$route.params.id === ":id" ? 5 : +this.$route.params.id;
      } else {
        this.depId = this.USER.userInfo.departments[0].id;
      }
    },
    async setBody(id) {
      this.loading = true;
      await this.getWorkTime(id);
      this.loading = false;
    },
    redactDay(day) {
      this.show = true;
      this.editedDay = day;
    },
    async deleteButton(item) {
      this.loading = true;
      let errors = [];
      for (let i = 0; i < item.length; i++) {
        let response = await this.deleteWorkTime(item[i].id);
        if (response.type !== "success") {
          errors.push(item[i].id, response.data);
        }
      }
      if (errors.length !== 0) {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: errors,
        });
      }
      await this.setBody(this.depId);
      this.loading = false;
    },
    returnRuDay(enDay) {
      return returnRuDayFunc(enDay);
    },
  },
};
</script>

<style lang="scss">
.phone-control__header {
  width: 100%;
  height: 80px;
  background-color: white;
  border-radius: 10px;
}
.error-box {
  margin-top: 20px;
}
.time {
  &_header {
    background: linear-gradient(to right, #e93e7b 50%, white 70%);
    margin-left: -12px;
    color: white;
    padding: 5px 10px;
    margin-bottom: 10px;
    @media (max-width: 500px) {
      margin-left: 0px;
      padding: 5px;
      justify-content: center;
      background: linear-gradient(90deg, #e93e7b 70%, white 90%);
    }
  }
  &_input {
    .v-input__slot {
      padding: 5px !important;
    }
    border: 2px solid #51c8f0;
    border-radius: 5px;
    align-items: center;
    max-width: 30%;
    @media (max-width: 500px) {
      max-width: 50%;
    }
    .v-input__slot::before {
      border-style: none !important;
    }
  }
  &_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
  }
}
.table {
  margin-top: 20px;
  &_header {
    background: linear-gradient(to right, #5d4bd0, #e93e7b);
  }
  th {
    background: none !important;
    color: white !important;
  }
  td {
    border-bottom: solid 1px #e93e7a45 !important;
  }
}
</style>
