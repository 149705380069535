<template>
  <v-dialog
    v-model="show"
    activator="parent"
    width="70%"
    @click:outside="$emit('update:show', false)"
  >
    <v-card class="modal" :dark="$dark.get()">
      <v-card-title class="flex justify-center">
        Создать график работы
      </v-card-title>
      <v-card-text>
        <v-form ref="timeForm">
          <div v-for="day in days" :key="day.dayEnum" class="day">
            <p>
              День : <span>{{ returnRuDay(day.dayEnum) }}</span>
            </p>
            <div v-if="day.dayStart !== null">
              <v-text-field
                v-model="day.dayStart"
                v-mask="'##:##'"
                label="Начало работы"
                :rules="[
                  $validate.required,
                  basicDataRule(day.dayStart, day.dayEnd),
                ]"
              >
              </v-text-field>
              <v-text-field
                v-model="day.dayEnd"
                v-mask="'##:##'"
                label="Конец работы"
                :rules="[
                  $validate.required,
                  basicDataRule(day.dayStart, day.dayEnd),
                ]"
              >
              </v-text-field>
              <v-btn small color="error" @click="makeDayFree(day)"
                >Выходной</v-btn
              >
            </div>
            <div v-else>
              <p>Нерабочий день</p>
              <v-btn small color="#5d4bd0" dark @click="makeDayWork(day)"
                >Сделать рабочим</v-btn
              >
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="modal__actions">
        <v-btn @click="$emit('update:show', false)">Закрыть</v-btn>
        <v-btn color="#5d4bd0" dark @click="createWeek()">Создать</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import api from "@/api";
import showMessage from "@/Functions/message";
import returnRuDayFunc from "@/Functions/returnRuDay";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    departmentId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      mode: "Добавить",
      input: {},
      days: [
        { dayEnum: "MONDAY", dayStart: "", dayEnd: "" },
        { dayEnum: "TUESDAY", dayStart: "", dayEnd: "" },
        { dayEnum: "WEDNESDAY", dayStart: "", dayEnd: "" },
        { dayEnum: "THURSDAY", dayStart: "", dayEnd: "" },
        { dayEnum: "FRIDAY", dayStart: "", dayEnd: "" },
        { dayEnum: "SATURDAY", dayStart: "", dayEnd: "" },
        { dayEnum: "SUNDAY", dayStart: "", dayEnd: "" },
      ],
    };
  },
  watch: {
    show(val) {
      if (!val) {
        this.$refs.timeForm.reset();
      }
    },
  },
  methods: {
    async createWeek() {
      if (!this.$refs.timeForm.validate()) {
        return;
      }
      const body = {
        departmentId: this.departmentId,
        workingDays: this.days,
      };
      let response = await api.TimeControl.createWeek(body);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("График создан", true);
        this.$emit("update:show", false);
        this.$emit("update-time");
      }
    },
    basicDataRule(start, end) {
      if (start && end && +(start.slice(0, 2) < +end.slice(0, 2))) {
        return true;
      } else return "Время начала работы должно быть ранее чем конец";
    },
    makeDayFree(day) {
      day.dayStart = null;
      day.dayEnd = null;
    },
    makeDayWork(day) {
      day.dayStart = "";
      day.dayEnd = "";
    },
    returnRuDay(enDay) {
      return returnRuDayFunc(enDay);
    },
  },
};
</script>
<style lang="scss" scoped>
.day {
  border: 1px solid black;
  padding: 10px;
  border-radius: 20px;
  span {
    color: black;
    font-weight: bold;
  }
  margin-bottom: 20px;
}
</style>
