export default function returnRuDay(enDay) {
  let ruDay;
  switch (enDay) {
    case "MONDAY":
      ruDay = "Понедельник";
      break;
    case "TUESDAY":
      ruDay = "Вторник";
      break;
    case "WEDNESDAY":
      ruDay = "Среда";
      break;
    case "THURSDAY":
      ruDay = "Четверг";
      break;
    case "FRIDAY":
      ruDay = "Пятница";
      break;
    case "SATURDAY":
      ruDay = "Суббота";
      break;
    case "SUNDAY":
      ruDay = "Воскресенье";
      break;
  }
  return ruDay;
}
